@media print {
  .Loader {
    display: none;
  }
}

.Loader-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Loader-logo {
    animation: Loader-logo-spin infinite 3s linear;
  }
}

.Loader-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-left: 0.5em;
}

@keyframes Loader-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
