.ApiRoute {
  text-align: left;
  margin: 1vmin;
}

#root
  > div
  > div.ApiRoute
  > div.swagger-ui
  > div:nth-child(2)
  > div.information-container.wrapper,
#root
  > div
  > div.ApiRoute
  > div.swagger-ui
  > div:nth-child(2)
  > div:nth-child(5) {
  display: none;
}
