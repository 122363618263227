body:has(div[class="StatisticsRoute"]),
body:has(div[class="FaqRoute"]),
body:has(div[class="PrivacyPolicyRoute"]),
body:has(div[class="LicenseRoute"]) {
  @media print {
    .BottomBar {
      display: none;
    }
  }
}
